<template>
  <v-card flat class="my-6 mx-9 rounded-lg px-4 py-8" color="#f5f7f7">
    <div class="d-flex justify-space-around align-center">
      <div class="date-card rounded-lg">
        <div class="d-flex justify-space-around align-center top rounded-t-lg">
          11-01-2021
        </div>
        <div class="bottom text-overline rounded-b-lg">loss date</div>
      </div>

      <div class="day-interval">
        <svg
          width="26"
          height="8"
          viewBox="0 0 26 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.646446 3.64645C0.451185 3.84171 0.451185 4.15829 0.646446 4.35355L3.82843 7.53553C4.02369 7.7308 4.34027 7.7308 4.53553 7.53553C4.7308 7.34027 4.7308 7.02369 4.53553 6.82843L1.70711 4L4.53553 1.17157C4.7308 0.976311 4.7308 0.659728 4.53553 0.464466C4.34027 0.269204 4.02369 0.269204 3.82843 0.464466L0.646446 3.64645ZM26 3.5L1 3.5V4.5L26 4.5V3.5Z"
            fill="black"
          />
        </svg>

        <span class="text-body-2 mx-2">1 day</span>

        <svg
          width="26"
          height="8"
          viewBox="0 0 26 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M25.3536 4.35355C25.5488 4.15829 25.5488 3.84171 25.3536 3.64644L22.1716 0.464464C21.9763 0.269202 21.6597 0.269202 21.4645 0.464464C21.2692 0.659726 21.2692 0.976309 21.4645 1.17157L24.2929 4L21.4645 6.82843C21.2692 7.02369 21.2692 7.34027 21.4645 7.53553C21.6597 7.73079 21.9763 7.73079 22.1716 7.53553L25.3536 4.35355ZM4.37114e-08 4.5L25 4.5L25 3.5L-4.37114e-08 3.5L4.37114e-08 4.5Z"
            fill="black"
          />
        </svg>
      </div>

      <div class="date-card rounded-lg">
        <div class="d-flex justify-space-around align-center top rounded-t-lg">
          12-01-2021
        </div>
        <div class="bottom text-overline rounded-b-lg">expiry date</div>
      </div>

      <div class="day-interval">
        <svg
          width="26"
          height="8"
          viewBox="0 0 26 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.646446 3.64645C0.451185 3.84171 0.451185 4.15829 0.646446 4.35355L3.82843 7.53553C4.02369 7.7308 4.34027 7.7308 4.53553 7.53553C4.7308 7.34027 4.7308 7.02369 4.53553 6.82843L1.70711 4L4.53553 1.17157C4.7308 0.976311 4.7308 0.659728 4.53553 0.464466C4.34027 0.269204 4.02369 0.269204 3.82843 0.464466L0.646446 3.64645ZM26 3.5L1 3.5V4.5L26 4.5V3.5Z"
            fill="black"
          />
        </svg>

        <span class="text-body-2 mx-2">35 days</span>

        <svg
          width="26"
          height="8"
          viewBox="0 0 26 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M25.3536 4.35355C25.5488 4.15829 25.5488 3.84171 25.3536 3.64644L22.1716 0.464464C21.9763 0.269202 21.6597 0.269202 21.4645 0.464464C21.2692 0.659726 21.2692 0.976309 21.4645 1.17157L24.2929 4L21.4645 6.82843C21.2692 7.02369 21.2692 7.34027 21.4645 7.53553C21.6597 7.73079 21.9763 7.73079 22.1716 7.53553L25.3536 4.35355ZM4.37114e-08 4.5L25 4.5L25 3.5L-4.37114e-08 3.5L4.37114e-08 4.5Z"
            fill="black"
          />
        </svg>
      </div>

      <div class="date-card rounded-lg">
        <div class="d-flex justify-space-around align-center top rounded-t-lg">
          14-02-2021
        </div>
        <div class="bottom text-overline rounded-b-lg">claim submitted</div>
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  props: ["details"],
};
</script>

<style lang="scss" scoped>
.date-card {
  min-width: 200px;
  text-align: center;
  border: 1px solid grey;
}

.top {
  height: 65px;
  background-color: white;
}

.bottom {
  background-color: grey;
  color: white;
  height: 32px;
}
</style>